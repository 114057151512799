import uikit from "./uikit.js"
import components from "./components.js"
// import Swup from "swup"

uikit()
components()
function uikitPageDropdown() {
  $(".js-uikit-pages-dropdown").on("click", (e) => {
    $(".front-ui-dropdown").toggleClass("active")
  })
}
uikitPageDropdown()

var app = {
  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },
  heroTextAnimation() {
    setTimeout(() => {
      $(".hero__txt").addClass("active")
    }, 750)
    $(".js-close-cookie").on("click", () => {
      $(".js-cookie").fadeOut("normal", function () {
        $(this).remove()
      })
    })
  },

  init: function () {
    app.load()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
  app.heroTextAnimation()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
